import { Controller } from "@hotwired/stimulus";

export default class UserMenu extends Controller {
  static targets = ["userMenu", "userMenuContent"];
  MENU_CONTENT_OPEN = "user-menu__content--open";

  isMenuOpen() {
    return this.userMenuContentTarget.classList.contains(
      this.MENU_CONTENT_OPEN
    );
  }

  handleOutsideClick = (event) => {
    // close the menu if anywhere on the page is clicked except the menu itself
    if (!this.element.contains(event.target)) {
      this.hideMenu();
    }
  };

  showMenu() {
    this.userMenuContentTarget.classList.add(this.MENU_CONTENT_OPEN);
  }

  hideMenu() {
    this.userMenuContentTarget.classList.remove(this.MENU_CONTENT_OPEN);
    document.removeEventListener("click", this.handleOutsideClick);
  }

  toggle = (event) => {
    // stop this click from bubbling up the document so that the event listener on the document doesn't immediately close the menu
    event.stopPropagation();

    if (this.isMenuOpen()) {
      this.hideMenu();
    } else {
      this.showMenu();
      document.addEventListener("click", this.handleOutsideClick);
    }
  };
}
