import { Controller } from "@hotwired/stimulus";

export default class ExpandItem extends Controller {
  static targets = ["expandButton", "expandItem"];

  static CLASS_ITEM_EXPANDED = "expanded";

  toggle() {
    this.expandItemTarget.classList.toggle(ExpandItem.CLASS_ITEM_EXPANDED);
  }

  open() {
    this.expandItemTarget.classList.add(ExpandItem.CLASS_ITEM_EXPANDED);
  }

  close() {
    this.expandItemTarget.classList.remove(ExpandItem.CLASS_ITEM_EXPANDED);
  }
}
