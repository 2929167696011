import { Controller } from "@hotwired/stimulus";

export default class LocationsSidepanel extends Controller {
  static targets = ["panel"];

  CLASS_PANEL_OPENNED = "panel-openned";

  toggle() {
    this.panelTarget.classList.toggle(this.CLASS_PANEL_OPENNED);
  }
}
