import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdown", "menu"];

  toggle() {
    this.dropdownTarget.classList.toggle("open");
    this.menuTarget.focus();
  }

  blur() {
    const dropdownTarget = this.dropdownTarget;

    setTimeout(() => {
      if (dropdownTarget.contains(document.activeElement)) {
        return;
      }
      this.close();
    }, 0);
  }

  close() {
    this.dropdownTarget.classList.remove("open");
  }
}
