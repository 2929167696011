const TabulateChart = function (
  data,
  {
    columns = (d) => {
      return d.length > 0 ? Object.keys(d[0]) : [];
    },
    labels = [],
    limit,
    select = "body",
  } = {}
) {
  var table = d3.select(select).append("table");
  var thead = table.append("thead");
  var tbody = table.append("tbody");

  // append the header row
  columns = typeof columns == "function" ? columns(data) : columns;
  if (labels.length == 0) {
    labels = columns;
  }
  thead
    .append("tr")
    .selectAll("th")
    .data(labels)
    .enter()
    .append("th")
    .text(function (column) {
      return column;
    });

  // create a row for each object in the data
  var rows = tbody.selectAll("tr").data(data).enter().append("tr");

  // create a cell in each row for each column
  var cells = rows
    .selectAll("td")
    .data(function (row) {
      return columns.map(function (column) {
        return { column: column, value: row[column] };
      });
    })
    .enter()
    .append("td")
    .text(function (d) {
      return d.value;
    });

  return table;
};

export default TabulateChart;
