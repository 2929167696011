import { Controller } from "@hotwired/stimulus";

export default class VerticalTabs extends Controller {
  static targets = ["tabsWrapper", "tabsList"];

  static fixedClass = "fixed";

  connect() {
    window.addEventListener("scroll", this.pageScroll.bind(this));
  }

  pageScroll() {
    const scroll = window.scrollY;
    const topPosition = this.tabsWrapperTarget.getBoundingClientRect().y + 150;

    if (scroll >= topPosition) {
      this.tabsListTarget.classList.add(VerticalTabs.fixedClass);
    } else {
      this.tabsListTarget.classList.remove(VerticalTabs.fixedClass);
    }
  }
}
