import { Controller } from "@hotwired/stimulus";

export default class Pagination extends Controller {
  static targets = ["list", "loadMore", "scrollTop"];

  static values = { page: Number };

  connect() {
    this.pageValue = 1; // Inicializa la página
  }

  loadMore() {
    this.pageValue += 1; // Incrementa el valor de la página cada vez que se carga más
    const url = `/search/pager${this.loadMoreTarget.dataset.params}`;

    // add class 'loading' to the list and to the loadMore button
    this.listTarget.classList.add("loading");
    this.loadMoreTarget.classList.add("loading");

    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .then(() => this.handleResponse(this))
      .catch((error) => console.error("Pagination Controller:", error));
  }

  handleResponse() {
    setTimeout(() => {
      this.listTarget.classList.remove("loading");
      this.loadMoreTarget.classList.remove("loading");
    }, 500);
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}
