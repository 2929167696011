import { Controller } from "@hotwired/stimulus";

export default class Styleguide extends Controller {
  static targets = ["navigation", "navigationItem", "navHolder"];

  connect() {
    this.navigationTarget.classList.remove("fixed");
    this.init();
  }

  init() {
    window.addEventListener("scroll", this.pageScroll.bind(this));
  }

  scrollTo(event) {
    event.preventDefault();
    const sectionId = event.currentTarget.href.split("#")[1];
    document.getElementById(sectionId).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  pageScroll() {
    const scroll = window.scrollY;
    const navFixedClass = "fixed";
    const navTop = this.navHolderTarget.getBoundingClientRect().y;

    if (scroll >= navTop) {
      this.navigationTarget.classList.add(navFixedClass);
    } else {
      this.navigationTarget.classList.remove(navFixedClass);
    }
  }
}
