import { Controller } from "@hotwired/stimulus";
import Plots from "../bundles/plots";

export default class Chart extends Controller {
  static targets = ["current"];

  generateChart() {
    const chartData = this.data.has("chart_data")
      ? JSON.parse(this.data.get("chart_data"))
      : { data: [], options: {} };

    const { data, options } = chartData;
    const chartType = this.data.get("chart_type");

    return Plots({ chartType, data, options }).render();
  }

  render() {
    try {
      this.currentTarget.append(this.generateChart());
    } catch (e) {
      console.debug(`Fatal: Unable to render target. ${e.message}`); // eslint-disable-line no-console
      console.debug("Did you declare the controller target?"); // eslint-disable-line no-console
      console.log(`Fatal: Unable to render target. ${e.message}`);
      console.log("Did you declare the controller target?");
    }
  }

  connect() {
    this.render();
  }
}
