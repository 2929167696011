import { Controller } from "@hotwired/stimulus";

export default class LocationsFilters extends Controller {
  static targets = [
    "form",
    "submit",
    "hiddenCountry",
    "hiddenState",
    "hiddenCity",
  ];

  static values = {
    regions: String,
  };

  connect() {
    this.CLASSES = {};
  }

  cleanFilters() {
    try {
      if (this.hiddenCountryTarget && this.hiddenCountryTarget.value) {
        this.hiddenCountryTarget.value = "";
      }
      if (this.hiddenStateTarget && this.hiddenStateTarget.value) {
        this.hiddenStateTarget.value = "";
      }
      if (this.hiddenCityTarget && this.hiddenCityTarget.value) {
        this.hiddenStateTarget.value = "";
      }
    } catch (error) {
      // console.debug(error);
    }

    this.submitForm();
  }

  removeCountry() {
    this.cleanFilters();
    this.submitForm();
  }

  removeState() {
    this.hiddenStateTarget.value = "";
    this.submitForm();
  }

  submitForm() {
    this.submitTarget.click();
  }
}
