import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  checked(event) {
    this.formTarget.requestSubmit();
    const icon = event.currentTarget
      .closest("span")
      .querySelector("span.caret");
    icon.classList.remove("caret");
    icon.classList.add(
      "icon",
      "loading",
      "animation",
      "rotate",
      "fa-duotone",
      "fa-spinner",
      "ms-2"
    );
  }
}
