import { Controller } from "@hotwired/stimulus";

export default class DashboardFilter extends Controller {
  static targets = [
    "refineSearch",
    "filterForm",
    "advancedPanelOpenInput",
    "advancedSearchPanel",
  ];

  isAdvancedSearchPanelOpen() {
    return this.advancedSearchPanelTarget.classList.contains(
      "remote-accordion--is-open"
    );
  }

  removeFilter(ev) {
    ev.target.closest(".filter-item").querySelector("input").checked = false;
    this.advancedPanelOpenInputTarget.value = this.isAdvancedSearchPanelOpen();
    this.filterFormTarget.requestSubmit();
  }

  cleanAllFilters() {
    this.advancedPanelOpenInputTarget.value = this.isAdvancedSearchPanelOpen();

    this.filterFormTarget
      .querySelectorAll("input[type='checkbox']")
      .forEach((checkbox) => (checkbox.checked = false));

    this.filterFormTarget.requestSubmit();
  }
}
