import { Controller } from "@hotwired/stimulus";

export default class RemoteAccordion extends Controller {
  static targets = ["trigger", "content"];

  static values = {
    contentId: String,
  };

  static CLASS_ITEM_EXPANDED = "remote-accordion--is-open";

  toggle() {
    this.contentTarget.classList.toggle(RemoteAccordion.CLASS_ITEM_EXPANDED);
    this.triggerTarget.classList.toggle(RemoteAccordion.CLASS_ITEM_EXPANDED);
  }
}
