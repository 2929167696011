import { Controller } from "@hotwired/stimulus";

export default class SearchTextOptions extends Controller {
  static targets = ["contextOptions", "selectedContextOptions"];

  connect() {
    this.updateSelectedOptions();
  }

  updateSelectedOptions() {
    if (this.hasContextOptionsTarget && this.hasSelectedContextOptionsTarget) {
      const checkboxes = this.contextOptionsTarget.querySelectorAll(
        "input[type='checkbox']"
      );

      checkboxes.forEach((checkbox) => {
        const label = this.selectedContextOptionsTarget.querySelectorAll(
          `li[data-id='${checkbox.id}']`
        )[0];

        if (checkbox.checked) {
          label.classList.remove("label-muted");
          label.classList.add("label-active");
          return;
        }

        label.classList.add("label-muted");
        label.classList.remove("label-active");
      });
    }
  }

  checked() {
    this.updateSelectedOptions();
  }
}
