import { Controller } from "@hotwired/stimulus";

export default class GlobalSearch extends Controller {
  static targets = [
    "search",
    "searchForm",
    "select",
    "input",
    "submitBtn",
    "searchResults",
    "searchWrapper",
    "commitField",
  ];

  static cssClass = {
    expand: "is-expanded",
    ready: "ready",
    openResultsPanel: "open",
    isSearching: "is-searching",
    isRefining: "is-refining",
  };

  connect() {
    this.updateInputStatus({ target: { value: this.inputTarget.value } });
  }

  toggleFilter() {
    if (
      this.searchWrapperTarget.classList.contains(
        GlobalSearch.cssClass.isRefining
      )
    ) {
      this.searchWrapperTarget.classList.remove(
        GlobalSearch.cssClass.isRefining
      );
      return;
    }
    this.searchWrapperTarget.classList.add(GlobalSearch.cssClass.isRefining);
  }

  focus() {
    if (this.textInputHasEnoughText()) {
      this.openResultsPanel();
    }
  }

  blur() {
    const isFocusInsideSearchComponent = this.searchWrapperTarget.matches(
      ":focus-within:not(:focus)"
    );

    if (isFocusInsideSearchComponent === false) {
      this.closeResultsPanel();
    }
  }

  textInputHasEnoughText() {
    const minimumCharacterSize = 3;
    const searchText = this.inputTarget.value;

    if (searchText.length >= minimumCharacterSize) {
      return searchText.charAt(searchText.length - 1) !== " ";
    }
    return false;
  }

  validateInput(ev) {
    if (ev.target.value.length > 0) {
      this.enableSubmitButton();
    } else {
      this.disableSubmitButton();
    }
  }

  toggleResultsPanel() {
    if (this.isResultsPanelOpen()) {
      this.closeResultsPanel();
      return;
    }
    this.openResultsPanel();
  }

  isResultsPanelOpen() {
    this.searchResultsTarget.classList.contains(
      GlobalSearch.cssClass.openResultsPanel
    );
  }

  openResultsPanel() {
    this.searchResultsTarget.classList.add(
      GlobalSearch.cssClass.openResultsPanel
    );
  }

  closeResultsPanel() {
    this.searchResultsTarget.classList.remove(
      GlobalSearch.cssClass.openResultsPanel
    );
  }

  isValidInput() {
    return this.inputTarget.value.length >= 3;
  }

  updateInputStatus() {
    if (this.isValidInput()) {
      this.submitBtnTarget.classList.add(GlobalSearch.cssClass.ready);
      return true;
    }

    this.submitBtnTarget.classList.remove(GlobalSearch.cssClass.ready);
    return false;
  }

  setSearchingState() {
    this.searchTarget.classList.add(GlobalSearch.cssClass.isSearching);
  }

  removeSearchingState() {
    this.searchTarget.classList.remove(GlobalSearch.cssClass.isSearching);
  }

  keyup(event) {
    event.preventDefault();
    event.stopPropagation();

    this.closeResultsPanel();
    this.updateInputStatus();
    clearTimeout(this.timeout);

    if (!this.isValidInput()) {
      this.removeSearchingState();
      return;
    }

    if (event.keyCode === 13) {
      return;
    }

    this.setSearchingState();

    this.timeout = setTimeout(() => {
      this.searchFormTarget.requestSubmit();

      setTimeout(() => {
        this.openResultsPanel();
        this.removeSearchingState();
      }, 1000);
    }, 500);
  }

  search(event) {
    event.preventDefault();
    event.stopPropagation();

    this.closeResultsPanel();
    this.updateInputStatus();
    clearTimeout(this.timeout);

    if (!this.isValidInput()) {
      this.removeSearchingState();
      return;
    }

    if (event.currentTarget.type !== "submit") {
      return;
    }

    this.commitFieldTarget.value = "Explore";
    this.closeResultsPanel();
    this.setSearchingState();
    this.timeout = setTimeout(() => {
      this.searchFormTarget.requestSubmit();
    }, 500);
  }

  clearSearch(event) {
    event.preventDefault();
    event.stopPropagation();

    this.closeResultsPanel();
    this.updateInputStatus();
    clearTimeout(this.timeout);

    if (!this.isValidInput()) {
      this.removeSearchingState();
      return;
    }

    if (event.currentTarget.type !== "submit") {
      return;
    }

    this.commitFieldTarget.value = "Clear";
    this.closeResultsPanel();
    this.setSearchingState();
    this.timeout = setTimeout(() => {
      this.searchFormTarget.requestSubmit();
    }, 500);
  }

  expand() {
    this.searchTarget.classList.add(GlobalSearch.cssClass.expand);
  }

  collapse() {
    this.searchTarget.classList.remove(GlobalSearch.cssClass.expand);
  }
}
