/* eslint-disable */
import {
  PieChart,
  ForceGraph,
  TabulateChart,
  Treemap,
} from "d3-charts/d3-charts"; // imported globally for the app, from the vendor js files

export default function Plots({ chartType, data, options }) {
  const plot_targets = [
    "bar",
    "horizontal_bar",
    "line",
    "stacked_bar",
    "stacked_dot",
    "heatmap",
    "gradient_bar",
    "correlation",
  ];

  const remove_empty = function (obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
  };

  const d3_targets = {
    pie: PieChart,
    forcegraph: ForceGraph,
    tabulate: TabulateChart,
    treemap: Treemap,
  };

  const fixAllLabelsLength = (data, options) => {
    const fixLabelLength = (label, maxLength) => {
      if (!maxLength || label.length <= maxLength) {
        return label;
      }
      return `${label.substring(0, maxLength - 4)}...`;
    };

    data = data.map((row) => {
      row.x = fixLabelLength(row.x, options.x_label_max_length);
      row.y = fixLabelLength(row.y, options.y_label_max_length);
      return row;
    });
    return { data, options };
  };

  const data_helpers = function (data, options) {
    if (typeof options.date_series !== "undefined") {
      data = data.map(function (row) {
        if (!!row[options.date_series]) {
          row[options.x] = new Date(row[options.date_series]);
        }
        return row;
      });
    }
    // ToDo: pass a slug form rails so we can use it to link to the page
    // right now we are using the text to slug and link so we can not modify it
    // return fixAllLabelsLength(data, options);
    return { data, options };
  };

  const formatted = data_helpers(data, options);
  let json = { marks: [] };

  return {
    DataHelpers: data_helpers,
    to_json: function () {
      return remove_empty({ ...json });
    },
    default_options: function (defaults) {
      json = { ...defaults, ...json };
      return this;
    },
    margins: function (options) {
      json = {
        ...json,
        width: options.width,
        height: options.height,
        title: options.title,
        subtitle: options.subtitle,
        caption: options.caption,
        marginLeft: options.marginLeft,
        marginRight: options.marginRight,
        marginTop: options.marginTop,
        marginBottom: options.marginBottom,
        aspectRatio: options.aspectRatio,
      };
      return this;
    },
    y_label: function (options) {
      json.y = {
        axis: options.y_axis || null,
        grid: options.y_grid || true,
        domain: options.yDomain,
        label: options.y_label,
        labelAnchor: options.y_label_anchor,
        percent: !!options.percent ? true : undefined,
        tickRotate: options.y_tick_rotate,
      };
      return this;
    },
    x_label: function (options) {
      json.x = {
        axis: options.x_axis,
        domain: options.xDomain,
        grid: options.x_grid,
        label: options.x_label,
        percent: !!options.percent ? true : undefined,
        tickRotate: options.x_tick_rotate,
      };
      return this;
    },
    color: function (options) {
      json.color = remove_empty({
        label: options.color_label,
        legend: options.color_legend,
        type: options.color_type,
        scheme: options.color_scheme,
        zero: options.color_zero,
      });
      return this;
    },
    style: function (options) {
      json.style = {
        fontSize: options.fontSize || options.font_size || "16px",
      };
      return this;
    },
    labels: function (options) {
      return this.y_label(options)
        .x_label(options)
        .color(options)
        .style(options);
    },
    sort_options: function (options, horizontal_bar = false) {
      if (horizontal_bar && typeof options.sort_descending !== "undefined") {
        return options.sort_descending ? { y: "x", reverse: true } : { y: "x" };
      }
      if (typeof options.sort_descending !== "undefined") {
        return options.sort_descending ? { x: "y", reverse: true } : { x: "y" };
      }

      return;
    },
    barX: function () {
      // barX is horizontal bar so inverts x,y
      return Plot.barX(
        formatted.data,
        remove_empty({
          x: options.y || "y",
          y: options.x || "x",
          fill: options.fill_key || "steelblue",
          sort: this.sort_options(options, true),
        })
      );
    },
    barY: function () {
      return Plot.barY(
        formatted.data,
        remove_empty({
          x: options.x || "x",
          y: options.y || "y",
          fill: options.fill_key,
          sort: this.sort_options(options),
        })
      );
    },
    cell: function () {
      return Plot.cell(
        formatted.data,
        remove_empty({
          x: options.x || "x",
          y: options.y || "y",
          fill: options.fill_key,
          inset: 0.5,
        })
      );
    },
    dot: function () {
      return Plot.dot(
        formatted.data,
        Plot.stackY2({
          x: (d) => d.x,
          y: (d) => d.y,
          fill: options.fill_key,
          title: options.mouseoverTitle,
        })
      );
    },
    linearRegressionY: function () {
      return options.linear_regression == true
        ? Plot.linearRegressionY(formatted.data, {
            x: options.x || "x",
            y: options.y || "y",
            stroke: options.linear_regression_stroke || "red",
          })
        : null;
    },
    lineY: function () {
      return Plot.lineY(
        formatted.data,
        remove_empty({
          x: options.x || "x",
          y: options.y || "y",
          interval: options.interval,
          stroke: options.stroke || "steelblue",
        })
      );
    },
    rectY: function () {
      return Plot.rectY(
        formatted.data,
        remove_empty({
          x: options.x || "x",
          y: options.y || "y",
          interval: options.interval,
          fill: options.fill_key,
        })
      );
    },
    ruleX: function () {
      return Plot.ruleX([0]);
    },
    ruleY: function () {
      return Plot.ruleY([0]);
    },
    selectLast: function () {
      return Plot.selectLast({
        x: options.x || "x",
        y: options.y || "y",
        z: options.fill_key,
        text: options.stroke || "black",
        textAnchor: "start",
        dx: 3,
      });
    },
    text_select_last: function () {
      return Plot.text(formatted.data, this.selectLast());
    },
    push: function (mark, options) {
      json.marks.push(mark);
      return this;
    },

    // Plot recipes
    bar: function () {
      return this.margins(options)
        .labels(options)
        .push(this.ruleY())
        .push(this.barY());
    },

    horizontal_bar: function () {
      return this.margins(options)
        .labels(options)
        .push(this.ruleX())
        .push(this.barX());
    },
    stacked_bar: function () {
      if (options.interval == "year") {
        return this.margins(options)
          .labels(options)
          .push(this.rectY())
          .push(this.ruleY());
      }

      return this.margins(options)
        .labels(options)
        .push(this.barY())
        .push(this.ruleY());
    },
    line: function () {
      return this.margins(options)
        .labels(options)
        .push(this.ruleY())
        .push(this.lineY())
        .push(this.text_select_last())
        .push(this.linearRegressionY());
    },
    stacked_dot: function () {
      return this.default_options({ aspectRatio: 1 })
        .margins(options)
        .labels(options)
        .push(this.dot())
        .push(this.ruleY());
    },
    stacked_histogram: function () {
      return this.margins(options)
        .labels(options)
        .push(
          Plot.rectY(
            formatted.data,
            Plot.binX(
              { y: options.yValue },
              { x: options.xValue, fill: options.yFill }
            )
          )
        )
        .push(this.ruleY());
    },

    correlation: function () {
      return this.default_options({
        color: { scheme: "BuRd", pivot: 0, legend: true, label: "doc_count" },
      })
        .labels(options)
        .color(options)
        .style({ fontSize: "16px" })
        .margins(options)
        .push(
          Plot.cellX(data, {
            x: "x",
            y: "y",
            fill: "doc_count",
            fillOpacity: (d) => (d.x === "" ? 0 : 1),
          })
        )
        .push(
          Plot.text(data, {
            x: "x",
            y: "y",
            text: (d) => d.doc_count,
            fill: (d) =>
              Math.abs(d.doc_count) < 25 || Math.abs(d.doc_count) > 100
                ? "white"
                : "black",
            fillOpacity: (d) => (d.x === "" ? 0 : 1),
          })
        )
        .push(this.ruleX());
    },

    /** see also https://observablehq.com/@observablehq/plot-impact-of-vaccines?intent=fork
     * https://observablehq.com/@observablehq/plot-seattle-temperature-heatmap?intent=fork
     * https://observablehq.com/@observablehq/plot-simpsons-ratings?intent=fork **/
    heatmap: function () {
      let maxYFill = data.reduce(
        (max, d) => Math.max(max, d[options.fill_key]),
        0
      );

      return this.default_options({
        width: 640,
        height: 400,
        label: "",
        padding: 0,
        maxYFill: maxYFill,
      })
        .margins(options)
        .labels(options)
        .style({ fontSize: options.font_size || "16px" })
        .push(this.cell())
        .push(
          Plot.text(formatted.data, {
            x: options.x || "x",
            y: options.y || "y",
            text: (d) => d[options.fill_key].toFixed(0),
            contrast: true,
            fill: (d) => {
              return d[options.fill_key].toFixed(0) > 0.4 * maxYFill
                ? "white"
                : "black";
            },
          })
        );
    },

    // D3 functions
    treemap: function () {
      const defaultOptions = {
        value: (d) => d.size, // size of each node (file); null for internal nodes (folders)
        group: (d, n) => n.ancestors().slice(-2)[0].data.name, // e.g., "animate" in flare/animate/Easing; color
        label: (d, n) =>
          [
            ...d.name.split(/(?=[A-Z][a-z])/g),
            n.value.toLocaleString("en"),
          ].join("\n"),
        title: (d, n) =>
          `${n
            .ancestors()
            .reverse()
            .map((d) => d.data.name)
            .join(".")}\n${n.value.toLocaleString("en")}`,
        link: (d, n) =>
          `https://github.com/prefuse/Flare/blob/master/flare/src/${n
            .ancestors()
            .reverse()
            .map((d) => d.data.name)
            .join("/")}.as`,
      };

      return d3_targets.treemap(data, {
        ...defaultOptions,
        ...options,
      });
    },
    pie: function () {
      const defaultOptions = {
        name: (d) => d.x,
        value: (d) => d.y,
        width: 300,
        height: 250,
      };

      return d3_targets.pie(data, {
        ...defaultOptions,
        ...options,
      });
    },
    // methods with 'node' argument operate on the node object, e.g., node.key
    // methods with 'd' argument operate on the forcegraph data object, e.g., d.index, d.x, d.y
    forcegraph: function () {
      // data.nodes should be sorted descending by doc_count
      let nodeMax =
        options.node_max || Math.max(...data.nodes.map((d) => d.doc_count));
      let nodeMin =
        options.node_min || Math.min(...data.nodes.map((d) => d.doc_count));
      let nodeScale = d3.scaleLinear().domain([nodeMin, nodeMax]);
      let linkScale = nodeScale;
      let count = function (id) {
        return data.links.filter(
          (link) => link.source === id || link.target === id
        ).length;
      };
      const defaultOptions = {
        width: 640,
        height: 400,
        nodeId: (node) => node.key,
        nodeGroup: (node) => {
          if (options.facet && node.facet == options.facet) {
            return node.facet;
          }

          return node.key;
        },
        nodeTitle: (node) => `${node.key}, ${node.doc_count}`,
        nodeLabel: (node) => {
          return !!options.label ? node[options.label] : node.key;
        },
        nodeStrength: (d) => {
          if (options.facet && data.nodes[d.index].facet == options.facet) {
            return -500;
          }
          return nodeScale.range([-250, -500]).clamp(true)(
            data.nodes[d.index]["doc_count"]
          );
        },
        nodeRadius: (d) => {
          if (options.facet && data.nodes[d.index].facet == options.facet) {
            return 25;
          }
          return nodeScale.range([10, 25]).clamp(true)(
            data.nodes[d.index]["doc_count"]
          );
        },
        linkStrength: (l) => {
          return 1 / Math.min(count(l.source.id), count(l.target.id));
        },
        linkDistance: (l) => {
          let index = l.source.index === 0 ? l.target.index : l.source.index;
          return linkScale.range([30, 70]).clamp(true)(
            data.nodes[index]["doc_count"]
          );
        },
        linkStrokeWidth: (l) => Math.sqrt(l.value),
        invalidation: null, // a promise to stop the simulation when the cell is re-run
      };

      return d3_targets.forcegraph(data, {
        ...defaultOptions,
        ...options,
      });
    },
    tabulate: function (_data, options) {
      const defaultOptions = {
        select: `[data-chart-title="${options.title}"]`,
      };

      return d3_targets.tabulate(data, {
        ...defaultOptions,
        ...options,
      });
    },

    createLink: function (element, link_to) {
      const url = `${link_to}/${element.textContent.replace(/ /g, "-")}`;
      element.classList.add("link"); // apply link styling
      element.title = url;
      element.addEventListener("click", () => window.open(url, "_blank"));
    },

    getYAxisLabels: function (chartId) {
      return document.querySelectorAll(
        `#${chartId} [aria-label="y-axis tick label"] text`
      );
    },

    render: function () {
      if (plot_targets.indexOf(chartType) !== -1) {
        // set links on y-axis labels
        if (options.y_axis_link_to) {
          setTimeout(
            () =>
              this.getYAxisLabels(options.id).forEach((el) =>
                this.createLink(el, options.y_axis_link_to)
              ),
            100
          );
        }
        return Plot.plot(this[chartType]().to_json());
      }

      if (d3_targets.hasOwnProperty(chartType)) {
        return this[chartType]();
      }

      throw new Error(
        "unrecognized-chart-type",
        `Unrecognized chart type: ${chartType}`
      );
    },
  };
}
