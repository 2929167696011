import { Controller } from "@hotwired/stimulus";

export default class Accordion extends Controller {
  static targets = ["accordion", "trigger", "content"];

  static CLASS_ITEM_EXPANDED = "is-open";

  toggle() {
    this.accordionTarget.classList.toggle(Accordion.CLASS_ITEM_EXPANDED);
  }

  open() {
    this.accordionTarget.classList.add(Accordion.CLASS_ITEM_EXPANDED);
  }

  close() {
    this.accordionTarget.classList.remove(Accordion.CLASS_ITEM_EXPANDED);
  }
}
