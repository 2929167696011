import { Controller } from "@hotwired/stimulus";

export default class NavMenu extends Controller {
  static targets = ["navMenu", "trigger", "menus"];

  static openClass = "open";

  toggleMenu() {
    if (!this.navMenuTarget.classList.contains(NavMenu.openClass)) {
      this.open();
      return;
    }

    this.close();
  }

  open() {
    this.navMenuTarget.classList.add(NavMenu.openClass);
  }

  close() {
    this.navMenuTarget.classList.remove(NavMenu.openClass);
  }
}
